import { useQuery, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import { dummy_products, queryOptions } from '../../types/defaults'
import SideBar from '../explore/components/sidebar'
import MainHeader from './components/headers/main'
import StockListGroup from './components/list/stockgroup'
import EmptyState from './components/pagestate/empty'
import ErrorState from './components/pagestate/error'
import LoadingState from './components/pagestate/loader'
import { ListGroupCont } from './components/styles'
import { Container, Main, TableCont } from './styles'
import InfiniteScroll from "react-infinite-scroll-component";
import { LocalCheckOutModal } from '../../components/modals'
import StockModal from '../../components/modals/stock'
import { GET_GLOBAL_PRODUCTS, GET_PRODUCTS } from '../../graphql/queries/product.query'
import { ChatModal } from '../../components/modals/chat'
import CircularButton from './components/buttons/circular'
import ChatBox from '../../components/chat'
import { showInventoryModal, tableState } from '../../globals'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'
import StocksCard, { RepoStocksCard } from '../../components/cards/stock'
import SideNavigator from '../../components/sidenav'
import AddStockBtn from './components/buttons/add'
import StocksLoader, { CardLoader } from '../../components/cards/loaders'
import { getLocalStore } from '../../utils'
import TableHeader from './components/headers/tablex'
import MainInventoryHeader from './components/headers/main'
import MainAppHeader from '../../components/topnav/headers/main'

const Repo = (props: any) => {

  const [acc, setAcc] = useState('')

  

  useEffect(() => {
    const account = getLocalStore()
    if (account) {
      setAcc(account)
    }
  }, [])

  const queries = useReactiveVar(queryOptions)

  let offset = 0;

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_GLOBAL_PRODUCTS, {
    variables: {
      ...queries,
      offset,
      group: queries.group ? queries.group : 'category',
    },
    fetchPolicy: "network-only",
  })

  if (data) {
    offset = offset + 10;
    console.log(data)
  }

  if (error) console.log({ ...error })

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        ...queries,
        offset: data?.globalProducts?.length,
      }
    })
  }
  const openEditors = () => {
    tableState({
      item: null,
      state: 'editing'
    })
    showInventoryModal(true)
  }

  return (
    <>
      <MainAppHeader pageTitle='Inventory' />
      <Main>
        
        <Container>
          <TableHeader />
          {
            loading ?
            <>
                <LoadingState />
                <CardLoader />
            </>
              :
              error ?
                <ErrorState retryCallback={refetch} />
                :
                !data.globalProducts.length ?
                  <EmptyState
                    clickCallback={openEditors}
                    btnLabel='Add'
                    message='No stocks so far'
                    subMessage='Stocks appears here when created'
                  />
                  :
                  <>
                    <TableCont>
                      <ListGroupCont>
                        <InfiniteScroll
                          dataLength={data.globalProducts.length}
                          next={fetchMoreData}
                          hasMore={true}
                          loader={null}
                          style={{ overflow: 'visible' }}
                        >
                          {
                            data.globalProducts.map((item: any) => (
                              <StockListGroup
                                {...props} 
                                account={acc}
                                key={item._id}
                                // groupId={items._id}
                                item={item}
                                // count={items.count}
                                // total={items.total}
                              />
                            ))
                          }
                        </InfiniteScroll>
                      </ListGroupCont>
                    </TableCont>
                    {/* <InfiniteScroll
                      dataLength={data.globalProducts.length}
                      next={fetchMoreData}
                      hasMore={true}
                      loader={null}
                      style={{ overflow: 'visible' }}
                    > */}
                      {
                        // data.globalProducts.map((item: any) => (
                          <RepoStocksCard
                            {...props}
                            fetchMoreDataCallback={fetchMoreData }
                         
                            // key={item._id}
                            //groupId={items._id}
                            items={data.globalProducts }

                            
                          />
                        // ))
                       } 
                    {/* </InfiniteScroll> */}
              </>
          }
        </Container>
        <CircularButton>Add to my store</CircularButton>
        <SideNavigator />

      </Main>
    </>
  )
}

export default Repo
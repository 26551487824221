import React from 'react'
import { Footer, Header, ItemsCont } from './styles'
import { Divider } from '../../../../../components/icons/styles'
import { Stock } from '../../stock';
import { queryOptions, sharedModal } from '../../../../../types/defaults';
import { useReactiveVar } from '@apollo/client';
import { formatFigures, format_date } from '../../../../../utils';
import { showStockEditModal, tableState } from '../../../../../globals';
import ShareForm from '../../forms/share';
import { Link } from 'react-router-dom';

const StockListGroup = (props: any) => {
    const { item, count, total, groupId } = props;
    const { group, filter } = useReactiveVar(queryOptions)

    const generateLink = (group:string,id: string)=>{
        return `/stocks?p=${group||'category'}&q=${encodeURIComponent(id)}&r=${filter}`;
    }
    const sm: any = useReactiveVar(sharedModal)

    return (
        <>
            {/* <Header>
                <h6>{group === 'date' ? format_date(groupId) : groupId || 'OTHERS'}</h6>
                {
                    count-5 > 0 && 
                    <h6>
                        <Link to={generateLink(group, groupId)}>SHOW ALL</Link>
                    </h6>
                }
                <Divider />
            </Header> */}
            {
                // items.map((stock: any) => (
                    <ItemsCont key={Math.random()}>
                        <Stock {...props} stock={item} />
                      
                    </ItemsCont>
                    // )
                // )
            }
            {/* <Footer>
                <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                <h6>{formatFigures(total)}</h6>
            </Footer> */}
        </>
    )
}

export default StockListGroup
import { useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { Divider } from "../../../../components/icons/styles";
import { cart, showCashBookModal, showCheckOutModal, tableState } from "../../../../globals";
import { globalInvoice } from "../../../../types/defaults";
import { MenuOptionsList, Option } from "./styles";

export const ActionList = (props: any) => {
    let { invoice, rt, showInfoCallback, deleteCallback, closeMenuCallback } = props

    const openInvoiceInfo = () => {
        showCashBookModal(true)
        tableState({
            ...tableState(),
            item: invoice, 
            state: 'details'
        })
    }

    const openCart = ()=>{
        showCheckOutModal(true)
        cart(invoice)
    }

    const handleDeleteInvoice = ()=>{

    }

//TODO: implement invoice deletion

    return (
        <MenuOptionsList
            {...props}
            onClick={() => closeMenuCallback()}
            rt={rt} onMouseLeave={() => closeMenuCallback()}
        >
            <Option onClick={()=>openCart()}>
                <p>Edit</p>
            </Option>
            <Option onClick={openInvoiceInfo}>
                <p>Show details</p>
            </Option>
            <Option onClick={(e: any)=>deleteCallback(e)}>
                <p>Delete invoice</p>
            </Option>
        </MenuOptionsList>
    )
}
import React from 'react'
import { HeaderMenuOpt, Opt } from './styles'
import { MenuOpt } from './opts'
import { useLocation } from 'react-router-dom'
import { catOptions, showListMenu } from '../../../../src/globals'
import { queryOptions } from '../../../types/defaults'
import { useReactiveVar } from '@apollo/client'

const HeaderOptList = (props: any) => {

    const { menu, optCat, modal, setOptCatCallback } =  props;

    const { pathname } = useLocation();
    const opt = useReactiveVar(catOptions)


    const selectOpt = (opt: string) => {
        showListMenu('')
        catOptions('')
        queryOptions({
            ...queryOptions(),
            group: opt
        })
    }

    const setFilterOpt = (filter: string) => {
        showListMenu('')
        catOptions('')
        queryOptions({
            ...queryOptions(),
            filter
        })
    }
   
  return (
      <HeaderMenuOpt show={(menu === 'main') && modal !== ''}>
          {
                menu === 'main' && opt==='' ?
                <>
                    <Opt onClick={() => catOptions('group')}>Group</Opt>
                    <Opt onClick={() => catOptions('filter')}>Filter</Opt>
                </>
                :
                opt === 'group' ?
                      <MenuOpt selectOptCallback={selectOpt} options={
                          pathname == '/cashbook' ?
                              ['A-Z', 'Company', 'Date added']
                              : pathname === '/cashbook/payouts' ?
                                  ['A-Z', 'Supplier', 'Method', 'Date added']
                                  : pathname === '/sales' ?
                                      ['Customer', 'Method', 'Date added']
                                      :
                                      ['A-Z', 'Category', 'Date added']
                } />
                :
                <>
                    <MenuOpt selectOptCallback={setFilterOpt} 
                    options={
                        pathname == '/sales' ?
                        ['All', 'Pending']
                            : 
                            ['All','Out', 'Running out', 'Expired', 'Expiring']
                                
                    } />
                </>
                
          }
      </HeaderMenuOpt>
  )
}

export default HeaderOptList
import { useReactiveVar } from "@apollo/client";
import { ReactElement, useState } from "react";
import { Divider } from "../../../../components/icons/styles";
import { globalInvoice, initInvoice, invCriteria, queryOptions } from "../../../../types/defaults";
import { Invoice as InvoiceModel } from "../../../../types/model";
import { formatFigures, format_date, getCartTotal, getNetInvoiceToatal, roundAmount, sortByPaid } from "../../../../utils";
import { Header } from "../../styles";

import { ElasticContainer } from "../containers/elastic";
import InvoiceInfo from "../details";
import { Invoice } from "../invoice";
import { Footer } from "../invoice/styles";
import { InnerList } from "./inner";
import { ItemsCont } from "./styles";
import { Link } from "react-router-dom";

export function OuterList(props: any): ReactElement {
    const { count, total, groupId } = props;
    const { group } = useReactiveVar(queryOptions)

    const [selectedIvoice, setSelectedInvoice] = useState(initInvoice)

    const { list } = props;
    const showInvoiceInfo = (i: any)=>{
        setSelectedInvoice(i)
    }

    const generateLink = () => {
        return `/sales/invoices?p=${group}&q=${encodeURIComponent(groupId)}`;
    }

    return (
        <section
            // onMouseLeave={() => showPrinterIcon(false)}
            // onMouseEnter={() => showPrinterIcon(true)}
            className="stocksContainer"
        >
            {
                <>
                    <Header>
                        <h6>{groupId}</h6>
                        {
                            count - 5 > 0 &&
                            <h6>
                                <Link to={generateLink()}>SHOW ALL</Link>
                            </h6>
                        }
                        <Divider />
                    </Header>
                    {
                        list.filter((r: any)=> r.stocks.length > 0).map((record: any, j: number) =>
                            <ElasticContainer key={record._id} contentHeight={55} initHeight={55}> 
                                    <ItemsCont>
                                        <Invoice 
                                            showInfoCallback={showInvoiceInfo} 
                                            stock={record.stocks[0]} 
                                            invoice={record} 
                                        /> 
                                    </ItemsCont>
                            </ElasticContainer>
                        )
                    }
                    <Footer>
                        <p>
                            +{
                             count - 5 <= 0 ? 0 : count - 5
                            }
                        </p> 
                        <h6> 
                            {/* {
                                filter === '' || filter === 'all' ? formatFigures(getNetInvoiceToatal(list.records)) : formatFigures(getCartTotal(list.records))
                            } */}
                            {roundAmount(total)}
                        </h6>
                    </Footer>
                </>
            }
        </section>
    )
} 
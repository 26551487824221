import { useMutation, useReactiveVar } from "@apollo/client"
import { SyntheticEvent, useEffect, useState } from "react"
import { Cancel, Chevron, TimesIcn } from "../../../../../components/icons"
import { Icon } from "../../../../../components/icons/styles"
import { showCashBookModal, tableState } from "../../../../../globals"

import { initPayout, queryOptions } from "../../../../../types/defaults"
import { SupplierModel } from "../../../../../types/model"
import { stripTypename } from "../../../../../utils"
import { updatePayOutCache, updateSupCache } from "../../../../../utils/caches"
import Button, { SaveButton } from "../../buttons"
import { Input } from "../inputs"
import { BtnCont, Column, ColumnCont, Form, FormContainer, FormGroup, FormGroupCont, HeaderCont } from "./styles"


import { CloseBtn } from "../../buttons/styles"
import DropDown from "./dropcont"
import { DropDownList, SuppliersDropDownList } from "./dropdown"
import { GET_PAYOUTS } from "../../../../../graphql/queries"
import { SAVE_PAYOUT } from "../../../../../graphql/mutations"

 
 const methods = [
    "Cash",
    "Card",
    "Transfer",
 ]
const PayoutForm = (props: any) => {

    const { state, item } = useReactiveVar(tableState)

    const queries = useReactiveVar(queryOptions)

    let [payout, setPayout] = useState(initPayout);

    const [focused, setFocused] = useState('')
    const [cancel, setCancel] = useState('')

    useEffect(() => {
        setPayout(item || initPayout)
    }, [item, state])

    const close = (e: Event) => {
        e.stopPropagation()
        tableState({
            item: null,
            state: ''
        })
        showCashBookModal(false)
    }

    const [savePayout, { error, loading, data }] = useMutation(SAVE_PAYOUT, {
        update: (cache, { data: { savePayout: newPayout } }) => {
            const prevPayout: any = cache.readQuery({
                query: GET_PAYOUTS,
                variables: {
                    ...queries,
                    group: 'date'
                }
            });
            // console.log(`prev: ${newPayout}`)
            // console.log(`np: ${JSON.stringify(newPayout, null, 2)}`);
            cache.writeQuery({
                query: GET_PAYOUTS,
                data: {
                    payouts: updatePayOutCache(prevPayout?.payouts, newPayout, 0, queries.group),
                },
            });
        }
    });

    if (error) {
        console.log({ ...error })
    }

    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = (name: string) => {
        setCancel(name);
    }
    const mouseLeave = (name: string) => {
        setCancel(name);
    }

    const handleClear = (name: string) => {
        setPayout({
            ...payout,
            [name]: ''
        })
    }

    const closeForm = (e: SyntheticEvent) => {
        e.preventDefault()
        tableState({
            state: '',
            item: null
        })
        showCashBookModal(false)
    }

    const handleChange = (e: any) => {
        e.persist();
        const { target: { name, value } } = e
        setPayout({
            ...payout,
            [name]: name ==='amount' || name ==='paid' ? +value : value
        })
    }
    const handleSupSelection = (supplier: any) => {
        setPayout({
            ...payout,
            supplier: stripTypename(supplier)
        });
        setFocused('')
    }
    const handleSelection = (method: any) => {
        setPayout({
            ...payout,
            method
        });
        setFocused('')
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (payout._id) {
            const { modified, ...sup } = stripTypename(payout)
            const { name, phone, email, company } = sup;
            payout = {
                ...sup,
                name: name.trim(),
                phone: phone.trim(),
                company: company.trim()
            } 
        } 
        savePayout({
            variables: {
                payout
            }
        })
    }


    return (
        <FormContainer show={state === 'editing'}>
            {state==='editing' && 
                <Form
                    noValidate={true}
                    {...props}
                    id={payout?._id}
                    onClick={(e: Event) => e.stopPropagation()}
                    onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}
                >
                    <HeaderCont>
                        <h6>New invoice</h6>
                        <CloseBtn title="Close" onClick={(e: any) => closeForm(e)}>
                            <Icon>
                                <TimesIcn size={8} color='black' />
                            </Icon>
                        </CloseBtn>
                    </HeaderCont>
                    <ColumnCont>
                        <Column>
                            <h6>Supplier</h6>
                            <FormGroupCont className="fgroup">
                                <FormGroup onMouseLeave={() => mouseLeave('supplier')} onMouseEnter={() => mouseEnter('supplier')}>
                                    <DropDown
                                        name='supplier'
                                        label='Supplier'
                                        value={payout.supplier.name}
                                        onClick={() => focus('supplier')}
                                    />
                                    <Icon id="cat" onClick={() => handleClear('supplier')}>
                                        <Chevron />
                                    </Icon>
                                    {
                                        focused === 'supplier' &&
                                        <SuppliersDropDownList
                                            mh={30}
                                            selectCallback={handleSupSelection}
                                            closeCallback={() => setFocused('')}
                                        />
                                    }
                                </FormGroup>

                            </FormGroupCont>
                        </Column>
                        <Column>
                            <h6>Payment</h6>
                            <FormGroupCont className="fgroup">

                                <FormGroup
                                    onMouseLeave={() => mouseLeave('amount')}
                                    onMouseEnter={() => mouseEnter('amount')} top>
                                    <Input
                                        required
                                        name='amount'
                                        type='number'
                                        label='Expected amount'
                                        value={payout.amount || null}
                                        focused={focused === 'amount'}
                                        placeholder='Specify items amount'
                                        changeCallback={(e: any) => handleChange(e)}
                                        focusedCallback={(name: string) => focus(name)}
                                    />
                                    {
                                        (cancel === 'amount') &&
                                        <Icon onClick={() => handleClear('amount')}>
                                            <Cancel />
                                        </Icon>
                                    }
                                </FormGroup>
                                <FormGroup
                                    onMouseLeave={() => mouseLeave('paid')}
                                    onMouseEnter={() => mouseEnter('paid')}>
                                    <Input
                                        name='paid'
                                        type='number'
                                        label='Initial payment'
                                        value={payout.paid || null}
                                        focused={focused === 'paid'}
                                        placeholder='Specify payment amount'
                                        changeCallback={(e: any) => handleChange(e)}
                                        focusedCallback={(name: string) => focus(name)}
                                    />
                                    {
                                        (cancel === 'paid') &&
                                        <Icon onClick={() => handleClear('paid')}>
                                            <Cancel />
                                        </Icon>
                                    }
                                </FormGroup>
                            </FormGroupCont>
                        </Column>

                        <Column>
                            <h6>Details</h6>
                            <FormGroupCont className="fgroup">
                                <FormGroup onMouseLeave={() => mouseLeave('method')} onMouseEnter={() => mouseEnter('method')} top>
                                    <DropDown
                                        name='method'
                                        label='Method'
                                        value={payout?.method}
                                        onClick={() => focus('method')}
                                    />
                                    <Icon id="cat" onClick={() => handleClear('method')}>
                                        <Chevron />
                                    </Icon>
                                    {
                                        focused === 'method' &&
                                        <DropDownList
                                            mh={90}
                                            name="method"
                                            header='Suppliers'
                                            options={methods}
                                            selectCallback={handleSelection}
                                            closeCallback={() => setFocused('')}
                                        />
                                    }
                                </FormGroup>
                                <FormGroup
                                    onMouseLeave={() => mouseLeave('remark')}
                                    onMouseEnter={() => mouseEnter('remark')}>
                                    <Input
                                        name='remark'
                                        label='Remark'
                                        value={payout.remark}
                                        focused={focused === 'remark'}
                                        placeholder='Purporse of payment'
                                        changeCallback={(e: any) => handleChange(e)}
                                        focusedCallback={(name: string) => focus(name)}
                                    />
                                    {
                                        (cancel === 'remark') &&
                                        <Icon onClick={() => handleClear('remark')}>
                                            <Cancel />
                                        </Icon>
                                    }
                                </FormGroup>
                            </FormGroupCont>
                        </Column>
                    </ColumnCont>
                    <BtnCont>
                        <Button
                            data={data}
                            loading={loading}
                            title={payout?._id ? 'Update invoice' : 'Create invoice'}
                            disabled={!payout.supplier.name || !payout.amount}
                        />
                    </BtnCont>
                </Form>
            }
            
        </FormContainer>
    )
}
export default PayoutForm
import styled from "styled-components"

export const Row = styled.div<any>`
    height: ${props => props.focus ? 450 : 45}px;
    transition: all .15s ease-in-out;
    width: 100%;
    align-items: center;
    background: #ffffff;
    border-radius: 23px;
    margin: auto;
    z-index: 2000;
    display: grid;
    margin: 14px 0px;
    position: relative;
    grid-template-columns: 30% 1fr 30%;
    box-shadow: ${props => props.focus ? 'rgba(196,195,195,0.216) 0px 5px 25px' : 'none'} ;
    @media(max-width: 768px) {
      width: 100%;
      height: 54px;
      border-radius: 26px;
      h4, .item {
        display: none;
      }
      display: flex; 
    }
`
export const Form = styled.form<any>`
    height: 47px;
    width: 100%;
    position: relative;
    padding: 0px 15px 0px 10px;
    grid-template-columns: 25px 1fr 30%;
    align-items: center;
    background: ${props => props.focus ? 'white' : '#f5f4f4'};
    display: ${props => props.mobile ? 'none' : 'grid'};
    /* border: 1px solid rgba(71, 75, 76, 0.055); */
    box-shadow: ${props => props.focus ? 'rgba(196,195,195,0.216) 0px 5px 25px' : 'none'} ;
    border-radius: 23.5px 23.5px ${props => props.focus ? 0 : 23}px ${props => props.focus ? 0 : 23}px;
    #more {
      margin-left: auto;
      display: none;
    }
    #cancel {
      margin-left: auto;
    }
    @media(max-width: 768px) {
        height: 53px;
        border-radius: 25px;
        padding: 0px 15px 0px 10px;
        width: 100%;
        border: none;
        background: #f0f0f0;
        grid-template-columns: 30px 1fr 30%;
        #more {
            display: block;
        }
    }
`
export const Input = styled.input.attrs((props: any) => ({
    placeholder: props.placeholder,
    className: 'form-control',
    autocomplete: 'off',
    name: 'search',

}))`
  padding: 10px 10px;
  width: 100%;
  height:100%;
  position: relative;
  border:none;
  align-items: center;
  background-color: inherit;
  font-size: 14px;
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: transparent;
    color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  }
  ::placeholder {
    color: lightgrey;
  }
`;

export const FormCont = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    .page__title {
      margin-bottom:0px;
      font-weight: 600;
      font-size: 22px;
      width: 100%;
      display: none;
      text-align: center;
      color: #25ec78;
    }
    h2 {
      font-size: 24px;
      font-weight: bold;
      color: #25ec78;
      margin-bottom: 0px;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
    @media(max-width: 768px) {
      .home {
        display: none;
      }
      .page__title {
        display: block;
      }
    }
`
export const Item = styled.div<any>`
  height: 100%;
  padding: 0px 10px;
  cursor: pointer;
  z-index: 7;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  color: #2d2c2c;
  overflow: visible;
  border-radius: 6px;
  height: 30px;
  width: 30px;
  margin-left: auto;
  background-color: #f8f8f8;
  cursor: pointer;
  border: 1px solid rgba(227, 227, 227, 1);

  &:hover {
    color: #012009;
    border: 1px solid rgba(227, 227, 227, 1)
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;
export const SearchSuggestions = styled.ul<any>`
    width: 100%;
    min-height: 240px;
    max-height: 400px;
    overflow-y: scroll;
    overflow:hidden;
    position: relative;
    right: 0px;
    padding: 0px;
    top: 47px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    position: absolute;
    border-radius: 0px 0px 14px 14px;
    border: 1px solid rgba(71, 75, 76, 0.055);
   box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
`

export const Suggestions = styled.li<any>`
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    p {
        position: relative;
        margin-bottom:0px;
        color: #393939;
        text-align: left;
        font-weight: 500;
        font-size: 13px;
        padding: 0px 0px;
    }
    :hover{
      background: whitesmoke;
     }
`

import { useState } from "react"
import { useParams } from "react-router-dom"
import { Header } from "./styles"
import { useLocation } from "react-router-dom"
import TopBar from "./topbar"
import { Outlet } from "react-router-dom"
import SearchBar from "../../../forms/search"
import TabLinksHeader from "../tabs"

const MainAppHeader = (props: any) => {

    const [menu, setMenu] = useState(false)

    const { category } = useParams()
    const {pathname} = useLocation() as any
    

    // const params = new URLSearchParams(location.search)
    const isPathTo = (name: string) => pathname.includes(name)

    

    const tabs:any = {
        cashbook: [
            { link: '/cashbook', label: 'Supplier' },
            { link: 'payouts', label: 'Payments' }
        ],
        inventory: [{ link: '/', label: 'My store' }, { link: '/global', label: 'Global store' }],
        sales: [
            { link: '/sales', label: 'Invoice' }, 
            { link: 'aggregates', label: 'Aggregates' }, 
            { link: 'payments', label: 'Payments' }
        ],
    }
    const resolvePath = () => isPathTo('sales') ? tabs.sales : isPathTo('cashbook') ? tabs.cashbook : tabs.inventory
    return (
        <>
            <Header>
                <TopBar />
                <SearchBar {...props} />
                <TabLinksHeader links={resolvePath()} />
            </Header>
            <Outlet />
        </>
    )
}

export default MainAppHeader
import styled from "styled-components";

export const NavCont = styled.nav<any>`
    top: 0;
    left: 0;
    transition: all .15s ease-in-out;
    height: 100vh;
    z-index: 4;
    position: fixed;
    background: #ffffff;
    overflow: hidden;
    position: fixed;
    border: 1px solid whitesmoke;
    width: ${props => props.opened ? 280 : 60}px;
    .sign {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 0px;
    }
    .acc {
        margin-top: auto;
    }
    h6 {
        display: ${props => props.opened ? 'block' : 'none'};
    }
     @media (max-width: 768px) {
        width: ${props => props.opened ? 80 : 0}vw;
    }

`
export const HamdbugerMenu = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  display: flex;
  width: 15px;
  height:12px;
  margin-right: 6px;
  .bar {
    width: 100%;
    height: 2px;
    background: #4a4949;
  }
  .bar:nth-child(2) {
    width: 80%;
    margin-right: auto;
    display: none;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0px;
    display: flex;
    height:14px;
    width: 15px;
  }
  
`
export const NavHeaderCont = styled.div`
    width: 100%;
    height: 200px;
    background: #ffffff;
    position: relative;
    #dsk {
        left: 20px;
        top: 30px;
        cursor: pointer;
    }
`
export const LinkItemsCont = styled.ul`
    width: 100%;
    padding: 20px 10px;
    display: flex;
    list-style: none;
    flex-direction: column;
    a {
        text-decoration: none;
        color: inherit;
    }
    .acc {
        margin-top: auto;
    }
`
export const LinkItem = styled.li<any>`
    width: 100%;
    height: 35px;
    padding: 0px 10px;
    border-radius: 8px;
    margin-bottom: 7px;
    font-size: 12px;
    position: relative;
    a,p {
        width: 100%;
        height: 100%;
        margin-bottom: 0px;
        position: absolute;
        display: grid;
        grid-template-columns: 30px 1fr;
        align-items: center;
        h6 {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 0px;
            width: 100%;
            text-align: left;
        }
    }
    background: ${props => props.active ? "#eae9e9" : "white"};

    cursor: pointer;
    :hover {
          background: whitesmoke; 
    }
    /* &:nth-child(3) {
        background: whitesmoke; 
    } */
    .icon {
        width: 14px;
        height: 14px;
        margin-right: 10px;
        z-index: 7777712;
        position: relative;
    }
    #sales {
        height: 16px;
        width: 16px;
    }
    #payment {
        height: 18px;
         width: 18px;
    }
    #cb {
        height: 18px;
         width: 18px;
    }
    #exp {
        height: 20px;
        width: 20px;
    }
    #orders {
        height: 18px;
         width: 18px;
    }
    #alt {
        height: 15px;
         width: 15px;
    }
    #account {
        height: 18px;
         width: 18px;
         svg {
            stroke: #141313;
         }
    }
`
export const NavModal = styled.div<any>`
  top: 0;
  left: 0;
  padding: 0px;
  height: 100vh;
  position: fixed;
  display: block;
  z-index: 4;
  background-color: rgb(0 0 0 / 40%);
  width: ${(props) => (props.opened ? 100 : 0)}vw;
`;

import React from "react";
import { createRoutesFromElements } from "react-router-dom";
import { Route } from "react-router-dom";
import {
    createBrowserRouter,
} from "react-router-dom";
import "./index.css";
import ErrorPage from "./pages/error";
import { Root } from "./pages/root";

import ProdDetaills from "./pages/details";
import Inventory from "./pages/inventory";
import Settings from "./pages/settings/main";
import Account from "./pages/settings/account";
import Address from "./pages/settings/address";
import SignIn from "./pages/signin";
import { Secured } from "./auth";
import InvoicePage from "./pages/invoices";
import Staff from "./pages/settings/staff";
import SignUp from "./pages/signup";

import Home from "./pages/home";
import Explore from "./pages/explore";
import Subscription from "./pages/settings/subscription";
import Store from "./pages/store/main";
import StoreItems from "./pages/store/stocks";
import Analytics from "./pages/analytics";
import Onboarding from "./pages/onboading";
import Stocks from "./pages/shownStocks";
import ShownInvoices from "./pages/shownInvoices";
import OrdersPage from "./pages/orders";
import Payments from "./pages/payments";
import ShownStocks from "./pages/shownStocks";
import { ExpensePage } from "./pages/expenses";
import Stores from "./pages/stores";
import MainHeader from "./pages/invoices/component/headers/main";
import Aggregates from "./pages/aggregates";
import Error from "./pages/error";
import ShownAggregates from "./pages/shownAggregates";
import Explorer from "./pages/explorer";
import MyStaff from "./pages/settings/subAccounts";
import MainInventoryHeader from "./pages/inventory/components/headers/main";
import Repo from "./pages/repo";
import CashBookMainHeader from "./pages/expenses/components/headers/main";
import { CashBookPayment } from "./pages/expenses/payouts";
import { Suppliers } from "./pages/expenses/suppliers";
import { History } from "./pages/expenses/history"; 
import MainAppHeader from "./components/topnav/headers/main";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="/"
            element={
                <Root />
            }
            errorElement={
                <Error />
            }
        >
            <Route 
                index
                element={
                    <Secured>
                        <Home />
                    </Secured>
            }
            />
            <Route
                path='signup'
                element={
                    <SignUp />
                }
            />
            <Route
                path='signin'
                element={
                    <SignIn />
                }
            />
            <Route
                path='onboarding'
                element=
                {
                    // <Secured>
                    <Onboarding />
                    // </Secured>
                }
            />
            {/* <Route
                path='explorer'
                element=
                {
                    <Explorer />
                }
            /> */}
            <Route
                path="/global"
                element={
                    <Secured>
                        <Repo />
                    </Secured>
                }
            />
            <Route
                path="stocks"
                element={
                    <Secured>
                        <ShownStocks />
                    </Secured>
                }
            />
            <Route
                path="stores"
                element={
                    <Stores />
                }
            />
            <Route
                path='invoice'
                element=
                {
                    <Secured>
                        <InvoicePage />
                    </Secured>
                }
            />
            <Route
                path='expenses'
                element=
                {
                    <Secured>
                        <ExpensePage />
                    </Secured>
                }
            />
            <Route
                path='payments'
                element=
                {
                    <Secured>
                        <Payments />
                    </Secured>
                }
            />
            <Route
                path='analytics'
                element=
                {
                    <Secured>
                        <Analytics />
                    </Secured>
                }
            />
            <Route
                path='orders'
                element=
                {
                    <Secured>
                        <OrdersPage />
                    </Secured>
                }
            />

            <Route
                path='product/:id'
                element=
                {
                    <ProdDetaills />
                }
            />
            <Route
                path="store/:id"
                element={
                    <Store />
                }
            >
                <Route index element={
                    <StoreItems />
                } />

            </Route>
            <Route
                path="sales/*"
                element={
                    <Secured>
                        <MainAppHeader pageTitle="Sales" /> 
                    </Secured>
                }
            >
                <Route index element={
                    <Secured>
                        <InvoicePage />
                    </Secured>
                } />
             
                <Route
                    path="invoices"
                    element={
                        <Secured>
                            <ShownInvoices />
                        </Secured>
                    }
                />
                <Route
                    path="aggregates"
                    element={
                        <Secured>
                            <Aggregates />
                        </Secured>
                    }
                />
                <Route
                    path="aggregates/view"
                    element={
                        <Secured>
                            <ShownAggregates />
                        </Secured>
                    }
                />
                <Route
                    path="payments"
                    element={
                        <Secured>
                            <Payments />
                        </Secured>
                    }
                />
            </Route>
            <Route
                path="cashbook/*"
                element={
                    <Secured>
                        <MainAppHeader pageTitle="Cash Book" />
                    </Secured> 
                }
            >
                <Route index element={
                    <Secured>
                        <Suppliers />
                    </Secured>
                } />
             
                <Route
                    path="payouts"
                    element={
                        <Secured>
                            <CashBookPayment />
                        </Secured>
                    }
                />
                <Route
                    path="history"
                    element={
                        <Secured>
                            <History />
                        </Secured>
                    }
                />
            </Route>
            <Route
                path="account/*"
                element={
                    <Secured>
                        <Settings />
                    </Secured>
                }
            >
                <Route index element={
                    <Secured>
                        <Account />
                    </Secured>
                } />
                <Route
                    path="address"
                    element={
                        <Secured>
                            <Address />
                        </Secured>
                    }
                />
                <Route
                    path="subscriptions"
                    element={
                        <Secured>
                            <Subscription />
                        </Secured>
                    }
                />
                <Route
                    path="staff"
                    element={
                        <Secured>
                            <MyStaff />
                        </Secured>
                    }
                />
            </Route>
        </Route>
    )
);
import styled from "styled-components";

export const ItemCont = styled.div`
    width: 100%;
    height: 60px;
    position: relative;
    overflow: hidden;
    font-size: ${props => props.theme.typography.body1};
    @media(max-width: 768px) {
    height: 64px;
   }
`
export const Item = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 10px;
  display: grid;
  position: relative;
  align-items: center;
  grid-template-columns: 45px 1fr auto ;
`

export const DesCont = styled.div.attrs(() => ({
    className: 'ds'
})) <any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${props => props.w || 100}%;
  border-bottom-color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.separators.sec : props.theme.light.colors.separators.pri
};
`
export const Desc = styled.div`
  height: 100%;
  width:85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  .desc--items {
    position: relative;
    bottom: 5px;
    .desc {
      position: relative;
      top: 8px;
    }
    .sub--desc {
      bottom: -3px;
      font-size: 11px;
      margin-bottom: 2px;
    }
  }
   p {
     margin-bottom: 0px;
     padding: 0px;
     padding-left: 10px;
     position: relative;
     width:170px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
    span {
        ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.sec : props.theme.light.colors.labels.sec};
    }
   }
   p:last-child {
     ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.tar : props.theme.light.colors.labels.sec}
   }
   @media(max-width: 768px) {
    padding-left: 10px;
   }
`;


export const SellingPriceInput = styled.input`
      border: none;
      outline: none;
      padding: 0px 0px;
      border-radius: 0px;
      line-height: .7;
      margin-bottom: 3px;
      color: rgba(52, 52, 53, 0.9);
      &:focus {
        box-shadow: none !important;
        background: whitesmoke;
      }
      &::placeholder {
        color: #cacaca;
      }
      font-size: 13px;
`

export const Input = styled.input.attrs(props => ({
    placeholder: props.placeholder,
    autocomplete: 'off',
    className: 'form-control'
}))`
  padding:0rem .22rem ;
  padding-left: 0rem;
  min-width:5px;
  /* width: 17px; */
  height: 20px;
  border-radius: inherit;
  border:inherit;
  background: inherit; 
  text-align: right;
  font-size: ${props => props.theme.typography.body1};
  &:focus {
    box-shadow: none;
    outline: none;
    background: inherit;
  }
`;

export const QInput = styled(Input)`
  padding: 0px;
  min-width: 10px;
  text-align: center;
`;
export const PriceInput = styled(Input)`
  width:60px;
  border-radius: 3px;
  text-align: left;
  color: rgba(60, 60, 67, 0.6);
  &:focus {
    box-shadow: none;
    outline: none;
    background: inherit;
    /* color: rgba(60, 60, 67, 0.6); */
  }
`;

export const QtyInputCont = styled.div<any>`
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    position:relative;
    min-width:60px;
    p {
        text-align: right;
        right: 5px;
        position: relative;
    }
`;

export const InputCont = styled.div.attrs(props => ({
    className: 'formGroup'
}))`
    background: inherit;
    height: auto;
    border: none;
    min-width:50px;
    position:relative;
    bottom: 4px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
      input {
            color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
          &::-webkit-input-placeholder {
              color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
          }
          &:focus {
              color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    }
      }
    }
  `

// export const Btn = styled.button<any>`
//   height: 30px;
//   width: 30px;
//   border-radius: 50%;
//   align-self: flex-end;
//   border:none;
//   position: absolute;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-left: auto;
//   right:15px;
//   bottom:10px;
//   p {
//     margin-bottom: 0px;
//     font-size: 12px;
//     color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.brand : props.theme.light.colors.brand
//     };
   
//   }
//   svg {
//     position: relative;
//     stroke: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.brand : props.theme.light.colors.brand
//     };
//   }
//   &:hover {
//       border: none;
//       background-color: ${props => props.disabled ? '' : '#00A3FE'};
//   }
//   :hover {
//     svg {
//       stroke: 'white'
//     }
//     p {
//       color: ${props => props.disabled ? '' : 'white'}

//     }
//   }
//   &:focus {
//       border: none;
//       background-color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.brand : props.theme.light.colors.brand
//     };
//   }
// `
export const Button = styled.button.attrs(props => ({
    className: 'icn'
}))`
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  outline: none;
  border: none;
  cursor: pointer;
    span: {
        display: block;
        height: 100%;
        width: 100%;
        bottom: 4px;
        position: relative;
    }
  font-size: 14px;
  font-weight: bold;
  color: grey;
  &:hover {
      background: rgba(206, 205, 205, 0.473);
      color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  }
`
export const SubTotal = styled.p<any>`
    width: auto;
    margin-bottom: 0px;
    text-align: right;
    position: absolute;
    font-size: ${props => props.fs || props.theme.typography.body1}px;
    color: ${props => props.cl || props.theme.light.colors.labels.sec};
    span {
        position: absolute;
        display: block;
        height: 15px;
        width: 15px;
        right: -16px;
        top:2px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        :hover {
            background:  rgba(211, 211, 226, 0.562);
        }
        svg {
            position: absolute;
        }
    }
`

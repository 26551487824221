import { useReactiveVar } from "@apollo/client"

import { DataInfo, DetContainer, HeaderCont, ImageCont, InfoSection, Item, ItemInfo, ListItem, ListItemCont } from "./styles"
import { showCashBookModal, tableState } from "../../../../globals"
import { Divider, Icon } from "../../../../components/icons/styles"
import { formatMoney, formatTime, format_date, getCartTotal } from "../../../../utils"
import { CloseBtn } from "../buttons/styles"
import { TimesIcn } from "../../../../components/icons"
import { CartItem } from "../../../../models"
import { getImageUrl } from "../../../../apollo"
import { initInvoice } from "../../../../globals/defaults"



const InvoiceDetails = (props: any) => {

    let { state, item } = useReactiveVar(tableState)
        item = item ?? initInvoice

    const close = (e: Event) => {
        e.stopPropagation()
        tableState({
            item: null,
            state: ''
        })
        showCashBookModal(false)
    }
    const getBalaceAmount = () => {
        return +getCartTotal(item?.stocks) - item?.recieved||0
    }

    return (
        <DetContainer h={item.stocks.length * 60}  show={state === 'details'}>
            <HeaderCont>
                <h6>Invoice details</h6>
                <CloseBtn title="Close" onClick={close}>
                    <Icon>
                        <TimesIcn size={8} color='black' />
                    </Icon>
                </CloseBtn>
            </HeaderCont>
            {
                item?.stocks.map((stock: CartItem, i: number) => (
                    <ListItemCont className='stock'>
                        <ImageCont> {
                            stock.item.mediaUrls[0] ?
                                <img
                                    src={getImageUrl(stock.item.mediaUrls[0])}
                                    alt="product"
                                />
                                :
                                null
                        }
                        </ImageCont>
                        <ItemInfo>
                            <DataInfo>
                                <p>{stock.item.name}</p>
                                <p>{stock.quantity}</p>
                            </DataInfo>
                            <DataInfo>
                                <p className='desc'>{stock.item.description}</p>
                                <p>{formatMoney(stock!.item!.sellingPrice * stock!.quantity)}</p>
                            </DataInfo>
                            {i + 1 !== item.stocks.length ? <Divider /> : null}
                        </ItemInfo>
                        <Divider />
                    </ListItemCont>
                ))
            }
            <ListItem>
                <Item>
                    <p>Total</p>
                    <p className='total'>{formatMoney(+getCartTotal(item.stocks))}</p>
                </Item>
                <Item>
                    <p>Paid</p>
                    <p>{formatMoney(item?.recieved)}</p>
                </Item>
                <Item>
                    <p>Balance</p>
                    <p>{formatMoney(getBalaceAmount())}{getBalaceAmount() === 0 ? '.00' : ''}</p>
                    <Divider />
                </Item>
                <Item>
                    <p>Method</p>
                    <p id='method'>{item.paymentMethod.toUpperCase()}</p>
                </Item>
                <Item>
                    <p>Status</p>
                    <p id='status'>{item.completed ? 'Completed' : 'Pending'}</p>
                    <Divider />
                </Item> 
                 <Item>
                    <p>Customer</p>
                    <p>{item?.customer?.firstName || '-'}</p>
                </Item> 
                <Item>
                    <p>Phone</p>
                    <p>{item?.customer ? item?.customer?.phone : '-'}</p>
                </Item> 
                <Item>
                    <p>Address</p>
                    <p>{item?.customer?.address || '-'}</p>
                    <Divider />
                </Item> 
                <Item>
                    <p>Date added</p>
                    <p>{format_date(item?.createdAt || new Date())} { formatTime(item?.createdAt || new Date()).toLowerCase()}</p>
                </Item>
                <Item>
                    <p>ID</p>
                    <p>{item?._id?.slice(-6).toLocaleUpperCase()}</p>
                </Item>
                <Item>
                    <p>Serial No.</p>
                    <p>{item?.tid}</p>
                </Item>
            </ListItem>
        </DetContainer>
    )
}
export default InvoiceDetails
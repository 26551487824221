import { useLocation,Link } from "react-router-dom"
import { queryOptions } from "../../../types/defaults"
import { HeaderCont, TabLink, HeaderMainCont, TabLinks, TabLinksCont } from "./styles";
import { Divider } from "../../icons/styles";

const TabLinksHeader= (props: any) => {
    const { pathname } = useLocation()

    const {links} = props

    const resetQueries = () => {
        queryOptions({
             ...queryOptions(), 
             group: 'date added' 
        })
    }

    const isPathTo = (name: string) => pathname.includes(name)
    
    const name: any = () => !pathname.split('/') || pathname.split('/').length === 2 ? `/${pathname.split('/').filter(Boolean).pop()}` : pathname.split('/').filter(Boolean).pop() ;


    return ( 
        <HeaderMainCont>
            <HeaderCont id="container">
                <TabLinksCont>
                    <TabLinks> {
                            links.map((ln:any)=>(
                                <TabLink onClick={resetQueries} active={`${name()}` === ln.link }>
                                    <Link to={ln.link}>{ln.label}</Link>
                                </TabLink>
                                )
                            )
                        }
                    </TabLinks>
                    <Divider />
                </TabLinksCont>
            </HeaderCont>
        </HeaderMainCont>
    )
}

export default TabLinksHeader
import styled from "styled-components"
export const HeaderMainCont = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
export const HeaderMenuOpt = styled.ul<any>`
  font-size: 12px;
  position: absolute;
  overflow: hidden;
  padding: 5px 0px;
  right: 0;
  z-index: 7;
  min-width: 150px;
  top: -4%;
  border-radius: 10px;
  background: white;
  box-shadow: 0 5px 25px #c4c3c337;
  transition: all .15s ease-in-out;
  transform: scale(${props => props.show ? 1 : 0});
  #more {
    margin-left: auto;
  }
  @media(max-width: 768px) {
      display: block;
    }
`
export const Opt = styled.li<any>`
  height: 35px;
  z-index: 8;
  width: 100%;
  font-size: 12px;
  font-weight: 500;

  display: flex;
  position: relative
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0px 14px;
  cursor: pointer;
  :hover {
    background: whitesmoke;
    cursor: pointer;
  }
  .lbl {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .icon {

  }
  
`
export const HeaderCont = styled.div.attrs({
    className: 'container'
})`
  height: 100%;
  max-width: 1000px;
  padding: 0px;
  @media (max-width: 960px) {
    max-width: 100%;
    padding: 0px;
  }
`
export const TabLinksCont = styled.div`
width: 100%;
height: 40px;
display: flex;
align-items: center;
justify-content: center;

`
export const TabLinks = styled.ul`
list-style: none;
padding: 0px;
height:100%;
display: flex;
align-items: center;
justify-content: center;
margin: 0px;
border-radius: 6px;
/* background: whitesmoke; */

`

export const TabLink = styled.li<any>`
 height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  a {
    text-decoration: none;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    /* text-transform: uppercase; */
    color: ${props => props.active ? 'black' : '#989797'};
    &:hover {
      color: #012009;
    }
    ::before {
    content: "";
    bottom: -2px;
    left: 0;
    height: 2px;
    width: 100%;
    position: absolute;
    border-radius: 5px;
    background: ${props => props.active ? 'black' : 'transparent'};
  }
  }
`
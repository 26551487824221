import { useQuery, useReactiveVar } from '@apollo/client'
import queryString from 'query-string'
import React, { useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import ChatBox from '../../components/chat'
import { LocalCheckOutModal } from '../../components/modals'
import { ChatModal } from '../../components/modals/chat'
import InvoiceModal from '../../components/modals/invoice'
import { GET_INVOICES } from '../../graphql/queries/invoice.query'
import { invCriteria, queryOptions } from '../../types/defaults'

import EmptyState from '../inventory/components/pagestate/empty'
import ErrorState from '../inventory/components/pagestate/error'
import LoadingState from '../inventory/components/pagestate/loader'

import MainHeader from './component/headers/main'

import { OuterList } from './component/list/outer'
import { Container, ItemListCont, ListGroupCont, Main, TableCont } from './styles'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'
import InvoiceCard from '../../components/cards/invoice'
import SideNavigator from '../../components/sidenav'
import TableHeader from './component/headers/tablex'
import { GET_INVOICE_AGGREGATES } from '../../graphql/queries'
import AggregateCard from '../../components/cards/aggregates'
import ExpenseModal from '../../components/modals/expenses'
import InvoiceDetails from '../expenses/components/details/invoice'

const Aggregates = (props: any) => {

    const location = useLocation();
    //const componentRef = useRef(null);

    const queries = useReactiveVar(queryOptions)

    const { search } = useLocation()

    const qs = queryString.parse(search)
    let offset = 0;

    const { data, loading, error, refetch, fetchMore, subscribeToMore } = useQuery(
        GET_INVOICE_AGGREGATES, {
            variables: {
                ...queries,
                offset,
                group: queries.group || 'day'
            },
            fetchPolicy: "network-only"
        }
    )

    if(data) {
        console.log(JSON.stringify(data, null, 2))
    }

    if (error) {
        console.log({...error})
    }

    const fetchMoreData = () => {
        fetchMore({
            variables: {
                ...queries,
                offset: data?.aggregates?.length,
            }
        })
    }
    return (
        <>
            <Main>
                <Container id="container">
                    <TableHeader />
                    <ItemListCont>
                        {
                            loading ?
                                <LoadingState />
                                :
                                error ?
                                    <ErrorState retryCallback={refetch} />
                                    :
                                    !data?.aggregates?.length ?
                                        <EmptyState
                                            clickCallback={refetch}
                                            message='No sales records so far'
                                            btnLabel='Refresh'
                                            subMessage='Invoices appears here when created'
                                        />
                                        :
                                        <>
                                            <TableCont>
                                                <ListGroupCont>
                                                    <InfiniteScroll
                                                        dataLength={data?.aggregates?.length}
                                                        next={fetchMoreData}
                                                        hasMore={true}
                                                        loader={<></>}
                                                        style={{ overflow: 'visible' }}
                                                    >
                                                        {
                                                            data?.aggregates.map((group: any, i: number) => (
                                                                <OuterList
                                                                    {...props}
                                                                    key={group._id}
                                                                    list={group}
                                                                    total={group.total}
                                                                    count={group.count} 
                                                                    groupId={new Date(group._id.y, group._id.m - 1, group._id.d)}
                                                                />
                                                            ))
                                                        }
                                                    </InfiniteScroll>
                                                </ListGroupCont>
                                            </TableCont>
                                            <InfiniteScroll
                                                dataLength={data.aggregates.length}
                                                next={fetchMoreData}
                                                hasMore={true}
                                                loader={null}
                                                style={{ overflow: 'visible' }}
                                            > {
                                                    data.aggregates.map((group: any, i: number) => (
                                                        <AggregateCard
                                                            {...props}
                                                            list={group}
                                                            total={group.total}
                                                            count={group.count}
                                                            groupId={group._id}
                                                            key={group.records[0]._id}
                                                            nextRecords={data.aggregates[i + 1]?.records || []}
                                                        />
                                                    ))
                                                }
                                            </InfiniteScroll>
                                        </>
                        }
                    </ItemListCont>
                </Container>
                <SideNavigator />
                <InvoiceModal />
                <ExpenseModal />
                <InvoiceDetails />
                <LocalCheckOutModal />
                <CheckOutOnlineModal />

            </Main>
        </>
    )

}

export default Aggregates
import { Fragment, ReactElement } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_EXPENSES } from "../../graphql/queries/expenses";
import styled from "styled-components";
import { Container, ItemListCont, ListGroupCont, Main, TableCont } from "./styles";
import { useLocation } from "react-router-dom";
import { queryOptions } from "../../types/defaults";
import LoadingState from "./components/pagestate/loader";
import ErrorState from "./components/pagestate/error";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyState from "./components/pagestate/empty";

import SideNavigator from "../../components/sidenav";
import AddStockBtn, { AddSupplierBtn } from "./components/buttons/add";
import PayoutSubList from "./components/payoutSubList";
import PayoutCard from "../../components/cards/payouts";
import PayoutForm from "./components/forms/payouts";
import ExpenseModal from "../../components/modals/expenses";
import { GET_PAYOUTS } from "../../graphql/queries";
import PayoutDetails from "./components/details/payout";
import RepaymentForm from "./components/forms/repayment";
import { GhostModal } from "../../components/modals";
import { PayOutTableHeader } from "./components/headers/tablex";

export const ItemWraper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
const Page = (props: any): ReactElement => {
    const location = useLocation();

    const queries = useReactiveVar(queryOptions) as any

    const { search } = useLocation()
    // const qs = queryString.parse(search)

    let offset = 0

    const { loading, data, error, refetch, fetchMore, subscribeToMore } = useQuery(GET_PAYOUTS, {
        variables: {
            ...queries,
            offset,
            group: queries.group ? queries.group : 'date added',
        },
        fetchPolicy: "network-only",
    })

    if (error) console.log({ ...error })

    const fetchMoreData = () => {
        fetchMore({
            variables: {
                ...queries,
                offset: data?.payouts?.length,
            }
        })
    }

    // let expenses:any = [];
    if (data) {
        // expenses = data?.expenses
        //console.log(data); 
    }

    return (
        <>
            <Main>
                <Container id="container">
                    <PayOutTableHeader />
                    <ItemListCont>
                    {
                        loading ?
                            <LoadingState />
                            :
                            error ?
                                <ErrorState retryCallback={refetch} />
                                :
                                !data.payouts.length ?
                                    <EmptyState
                                        // clickCallback={openEditors}
                                        btnLabel='Add'
                                        message='No payments'
                                        subMessage='Click the add button to your first invoice'
                                    />
                                    :
                                    <>
                                        <TableCont>
                                            <ListGroupCont>
                                                <InfiniteScroll
                                                    dataLength={data.payouts.length}
                                                    next={fetchMoreData}
                                                    hasMore={true}
                                                    loader={null}
                                                    style={{ overflow: 'visible' }}
                                                >
                                                    {
                                                        data.payouts.map((items: any) => (
                                                            <PayoutSubList
                                                                {...props}
                                                                key={items._id}
                                                                groupId={items._id}
                                                                list={items.records}
                                                                count={items.count}
                                                                totalAmount={items.totalAmount}
                                                                totalPaid={items.totalPaid}
                                                                totalBal={items.totalBal}
                                                            />
                                                        ))
                                                    }
                                                </InfiniteScroll>
                                            </ListGroupCont>
                                        </TableCont>

                                        <InfiniteScroll
                                            dataLength={data.payouts.length}
                                            next={fetchMoreData}
                                            hasMore={true}
                                            loader={null}
                                            style={{ overflow: 'visible' }}
                                        > {
                                                data.payouts.map((items: any) => (
                                                    <PayoutCard
                                                        {...props}
                                                        key={items._id}
                                                        groupId={items._id}
                                                        list={items.records}
                                                        count={items.count}
                                                        totalAmount={items.totalAmount}
                                                        totalPaid={items.totalPaid}
                                                        totalBal={items.totalBal}
                                                    />
                                                ))
                                            }
                                        </InfiniteScroll>
                                    </>
                    }
                        </ItemListCont>
                    </Container>
                <ExpenseModal />
                <PayoutForm />
                <RepaymentForm />
                <PayoutDetails /> 
                <SideNavigator />
                <AddStockBtn />
                <AddSupplierBtn />
                <GhostModal />
            </Main>
        </>
    )
}

export const CashBookPayment = Page;
import { Fragment, ReactElement } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_EXPENSES } from "../../graphql/queries/expenses";
import styled from "styled-components";
import { Container, ItemListCont, ListGroupCont, Main, TableCont } from "./styles";
import { useLocation } from "react-router-dom";
import { queryOptions } from "../../types/defaults";
import LoadingState from "./components/pagestate/loader";
import ErrorState from "./components/pagestate/error";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyState from "./components/pagestate/empty";

import ExpenseModal from "../../components/modals/expenses";
import ExpenseCard from "../../components/cards/expense";
import SideNavigator from "../../components/sidenav";
import {AddSupplierBtn} from "./components/buttons/add";
import SupplierListGroup from "./components/supplierGroup";
import SupplierCard from "../../components/cards/suppliers";
import ExpenseForm from "./components/forms/expense";
import SupplierForm from "./components/forms/suppliers";
import { GET_SUPPLIERS } from "../../graphql/queries/supplier.query";
import SupplierDetails from "./components/details/supplier";
import { showListMenu } from "../../globals";
import { GhostModal } from "../../components/modals";
import { SupplierTableHeader } from "./components/headers/tablex";

export const ItemWraper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
const Page = (props: any): ReactElement => {
    const location = useLocation();

    const queries = useReactiveVar(queryOptions) as any
    // const menuId = useReactiveVar(showListMenu) as any
    // console.log(`id:${showListMenu()}`)

    const { search } = useLocation()
    // const qs = queryString.parse(search)

    let offset = 0

    const { loading, data, error, refetch, fetchMore, subscribeToMore } = useQuery(GET_SUPPLIERS, {
        variables: {
            ...queries,
            offset,
            group: queries.group ? queries.group : 'date added',
        },
        fetchPolicy: "network-only",
    })

    if (error) console.log({ ...error })

    const fetchMoreData = () => {
        fetchMore({ 
            variables: {
                ...queries,
                offset: data?.suppliers?.length,
            }
        })
    }

    // let expenses:any = [];
    if (data) {
        // expenses = data?.expenses
         //console.log(data);
    }

    return (
        <>
            <Main>
                <Container id="container">
                    <SupplierTableHeader />
                    <ItemListCont>
                    
                    {
                        loading ?
                            <LoadingState />
                            :
                            error ?
                                <ErrorState retryCallback={refetch} />
                                :
                                !data.suppliers.length ?
                                    <EmptyState
                                        btnLabel='Add'
                                        message='No suppliers so far'
                                        subMessage='Click the add button to add your first supplier'
                                    />
                                    :
                                    <>
                                        <TableCont>
                                            <ListGroupCont>
                                                <InfiniteScroll
                                                    dataLength={data.suppliers.length}
                                                    next={fetchMoreData}
                                                    hasMore={true}
                                                    loader={null}
                                                    style={{ overflow: 'visible' }}
                                                >
                                                    {
                                                        data.suppliers.map((items: any) => (
                                                         <SupplierListGroup
                                                                {...props}
                                                                key={items._id}
                                                                groupId={items._id}
                                                                list={items.records}
                                                                count={items.count}
                                                            />
                                                        ))
                                                    }
                                                </InfiniteScroll>
                                            </ListGroupCont>
                                        </TableCont>

                                        <InfiniteScroll
                                            dataLength={data.suppliers.length}
                                            next={fetchMoreData}
                                            hasMore={true}
                                            loader={null}
                                            style={{ overflow: 'visible' }}
                                        > {
                                                data.suppliers.map((items: any) => (
                                                    <SupplierCard
                                                        {...props}
                                                        key={items._id}
                                                        groupId={items._id}
                                                        list={items.records}
                                                        count={items.count}
                                                    />
                                                ))
                                            }
                                        </InfiniteScroll>
                                    </>
                    }
                        </ItemListCont>
                    </Container>
                <ExpenseModal />
                <SupplierForm />
                <SupplierDetails />
                <SideNavigator />
                <AddSupplierBtn />
                <GhostModal />
            </Main>
            
        </>
    )
}

export const Suppliers = Page;
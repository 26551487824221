import styled from "styled-components";

export const ImageCont = styled.div`
  width: 50px;
  height: 50px;
  border: none;
  position: relative;
  border-radius: 6px;
  position: relative;
  //z-index: 1;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
`;
export const ExpiredMark = styled.div<any>`
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; 
    background-color: ${({ expiryStatus }) => expiryStatus === 'weak' ? '#ffd108' : 'red'}; 
`
export const ExpiredDate = styled(ExpiredMark)`
    top: 65%;
    left: 26%;
    width: 32px;
    height: 12px;
    border-radius: 6px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; 
    background-color: rgba(0,0,0,.36);
    p {
        font-size: 9px;
        color: white;
        position: relative;
        margin-bottom: 0px;
        font-weight: 700;
    }
`
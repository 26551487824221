import { useQuery, useReactiveVar } from '@apollo/client'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import ChatBox from '../../components/chat'
import { LocalCheckOutModal } from '../../components/modals'
import InvoiceModal from '../../components/modals/invoice'
import { GET_SHOWN_INVOICES } from '../../graphql/queries/invoice.query'
import { invCriteria, queryOptions } from '../../types/defaults'

import EmptyState from '../inventory/components/pagestate/empty'
import ErrorState from '../inventory/components/pagestate/error'
import LoadingState from '../inventory/components/pagestate/loader'

import { ShownInvoiceList } from './component/list/shown'
import { Container, ItemListCont, ListGroupCont, Main, TableCont } from './styles'
import TableHeader from './component/headers/tablex'
import ShowInvoiceCard from '../../components/cards/invoice/shown/shown'
import SideNavigator from '../../components/sidenav'
import InvoiceDetails from '../expenses/components/details/invoice'
import ExpenseModal from '../../components/modals/expenses'

const ShownInvoices = (props: any) => {

    const location = useLocation();

    const queries = useReactiveVar(queryOptions)

    let offset = 0;

    const params = new URLSearchParams(location.search)

    const { data, loading, error, fetchMore, refetch } = useQuery(GET_SHOWN_INVOICES, {
        variables: {
            p: params.get("p"),
            q: params.get("q"),
            offset,
            s: queries.query
        },
        fetchPolicy: "network-only",
    })

    if (data) {
        console.log(data)
        offset = offset + 10;
    }

    if (error) console.log({ ...error })

    const fetchMoreData = () => {
        fetchMore({
            variables: {
                p: params.get("p"),
                q: params.get("q"),
                offset: data?.shownInvoices?.length,
                s: ''
            }
        })
    }

    let curInvoices: any[] = [];

    if (data) {
        curInvoices = data?.shownInvoices
        offset = offset + 10;
    }
    if (error) {
        console.log(JSON.stringify(error, null, 2))
    }


    return (
        <>
            <Main>
                <Container id="container">
                    <TableHeader />
                    <ItemListCont>
                        {
                            loading ?
                                <LoadingState />
                                :
                                error ?
                                    <ErrorState retryCallback={refetch} />
                                    :
                                    !data?.shownInvoices?.length ?
                                        <EmptyState
                                            clickCallback={refetch}
                                            message='No sales records so far'
                                            btnLabel='Refresh'
                                            subMessage='Invoices appears here when created'
                                        />
                                        :
                                        <>
                                            <TableCont>
                                                <ListGroupCont>
                                                    <InfiniteScroll
                                                        dataLength={data?.shownInvoices?.length}
                                                        next={fetchMoreData}
                                                        hasMore={true}
                                                        loader={<></>}
                                                        style={{ overflow: 'visible' }}
                                                    >
                                                        {
                                                            <ShownInvoiceList
                                                                groupId={params.get("q")}
                                                                list={data.shownInvoices}
                                                            />
                                                        }
                                                    </InfiniteScroll>
                                                </ListGroupCont>
                                            </TableCont>
                                            <InfiniteScroll
                                                dataLength={data.shownInvoices.length}
                                                next={fetchMoreData}
                                                hasMore={true}
                                                loader={null}
                                                style={{ overflow: 'visible' }}
                                            > {
                                                    <ShowInvoiceCard
                                                        {...props}
                                                        groupId={params.get("p")}
                                                        list={data.shownInvoices}
                                                    />
                                                }
                                            </InfiniteScroll>
                                        </>
                        }
                    </ItemListCont>
                </Container>
                <InvoiceModal />
                <ExpenseModal />
                <InvoiceDetails />
                <SideNavigator />
                <LocalCheckOutModal />
            </Main>
        </>
    )

}

export default ShownInvoices